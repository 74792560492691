import React, { useState } from 'react';
import Header from '../Components/Header';
import TextContainer from '../Components/TextContainer';

const InputStudentId = ({ onConfirm }) => {
    const [studentId, setStudentId] = useState('');

    const handleButtonClick = (value) => {
        if (studentId.length < 10) {
            setStudentId(studentId + value);
        }
    };

    const handleDelete = () => {
        setStudentId(studentId.slice(0, -1));
    };

    const handleClear = () => {
        setStudentId('');
    };

    const handleConfirm = () => {
        if (studentId.length >= 5 && studentId.length <= 10 && onConfirm) {
            onConfirm(studentId);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen py-8">
            <Header />
            {/* 중앙에 위치한 컨테이너 */}
            <div className="flex flex-col items-center justify-center w-full max-w-2xl space-y-10">
                <div className="mb-8">
                    <TextContainer
                        topText="학번을 입력해주세요"
                        bottomText="Please enter your student number"
                    />
                </div>

                {/* 중간에 학번 입력창 */}
                <input
                    type="text"
                    value={studentId}
                    maxLength={8}
                    readOnly
                    className="mb-6 p-4 text-5xl font-normal text-center text-gray-700 border-b-4 border-gray04 bg-transparent focus:outline-none w-full"
                    style={{ maxWidth: '800px' }} // 입력창 너비
                />

                {/* 하단에 학번 입력용 키패드 */}
                <div className="grid grid-cols-3 gap-10 w-full" style={{ maxWidth: '800px' }}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
                        <button
                            key={num}
                            onClick={() => handleButtonClick(num)}
                            className="p-10 text-5xl font-normal bg-gray05 rounded-lg"
                        >
                            {num}
                        </button>
                    ))}
                    <button onClick={handleDelete} className="p-10 text-5xl font-normal bg-gray05 rounded-lg">
                        &#x2190;
                    </button>
                    <button
                        onClick={() => handleButtonClick(0)}
                        className="p-10 text-5xl font-normal bg-gray05 rounded-lg"
                    >
                        0
                    </button>
                    <button
                        onClick={handleConfirm}
                        className={`p-10 text-5xl font-semibold rounded-lg transition-colors duration-500 ${
                            studentId.length === 8 ? 'text-primary' : 'text-gray-700'
                        }`}
                    >
                        확인
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InputStudentId;
