import React from 'react';
import { useNavigate } from 'react-router-dom';

// 재사용 가능한 텍스트 블록 컴포넌트
// 이 컴포넌트는 텍스트와 클래스명을 받아 다양한 스타일의 텍스트를 렌더링합니다.
const TextBlock = ({ text, className }) => (
  <div className={`font-pretendard ${className}`}>
    {text}
  </div>
);


const Button = ({ topText, middleText, bottomText, imgSrc, altText, route }) => {
  const navigate = useNavigate();

  return (
    <button 
      className="flex flex-col w-[370px] h-[564px] p-[130px_70px] items-start gap-[40px] 
                 rounded-[30px] border-2 border-[gray06] bg-white hover:bg-gray05 transition-colors"
      onClick={() => navigate(route)} //지정된 경로로 이동
    >
      {/* 텍스트 블록을 감싸는 컨테이너 */}
      <div className="flex flex-col h-[174px] gap-[19px] w-full items-start">
        
        {/* 최상단 텍스트 */}
        <TextBlock
          text={topText}
          className="text-gray03 text-[27px] font-light tracking-[-0.81px]"
        />
      
        {/* 중간 텍스트 */}
        <TextBlock
          text={middleText}
          className="text-primary text-[57px] font-bold leading-normal tracking-[-1.71px]"
        />
        
        {/* 하단 텍스트 */}
        <TextBlock
          text={bottomText}
          className="text-gray03 text-[25px] font-light tracking-[-0.81px]"
        />
      </div>

      {/* 유동적으로 변경 가능한 이미지 */}
      <img
        src={imgSrc}
        alt={altText} 
        className="mt-auto w-full h-auto object-cover rounded-[10px]"
      />

    </button>
  );
};

export default Button;
