import React from 'react';
import TextContainer from '../Components/TextContainer';
import Header from '../Components/Header';
import SeatsContainer from '../Components/SeatContainer';

const Reservation = () => {
    return (
        <div className="bg-[#ffffff] min-h-screen w-full pt-40"> {/* 배경색과 화면 크기를 설정 */}
            <Header />
            <TextContainer
                topText="좌석을 선택해주세요"
                bottomText="Please choose your seat"
            />
            
            {/* SeatsContainer와 하단 이미지 사이에 간격 추가 */}
            <div className="my-14">
                <SeatsContainer />
            </div>

            {/* 회색 얇은 선 */}
            <hr className="border-t border-gray-300 my-20 w-full mt-40" />

            {/* 하단 이미지 크기 조정 */}
            <img src="/img/map.png" alt="complete" className="mb-10 mx-auto w-[95%] max-w-[800px]" />
        </div>
    );
};

export default Reservation;
