import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();

    return (
        <div className="fixed top-0 left-0 right-0 flex items-center gap-[10px] w-full h-[249.934px] p-[10px_10px_10px_96px] flex-shrink-0 z-50">
            {/* 이전으로 돌아가는 버튼 이미지 */}
            <img 
                src="/img/back-button.svg" 
                alt="Back Button" 
                className="cursor-pointer"
                onClick={() => navigate(-1)} 
            />
            <div className="flex-1">
            </div>
        </div>
    );
};

export default Header;
