import React, { useEffect } from 'react';
import Header from '../Components/Header';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { seatState, returnSeatByIndexSelector } from '../recoil/recoilState';

const Test = () => {
  const [seats, setSeats] = useRecoilState(seatState); // Recoil 상태 관리
  const returnSeatByIndex = useSetRecoilState(returnSeatByIndexSelector); // 퇴실 처리하는 셀렉터 사용

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setSeats(prevSeats =>
        prevSeats.map(seat => {
          if (seat.isRented && seat.endTime) {
            const endTime = new Date(seat.endTime);
            if (now >= endTime) {
              return {
                ...seat,
                isRented: false,
                studentId: null, // 대여 종료 시 학번 정보 초기화
                startTime: null,
                endTime: null,
              };
            }
          }
          return seat;
        })
      );
    }, 1000); // 1초마다 종료 시간 체크

    return () => clearInterval(interval);
  }, [setSeats]);

  // 강제 퇴실 처리
  const handleForceCheckout = (index) => {
    returnSeatByIndex(index); // returnSeatByIndexSelector를 통해 퇴실 처리
  };

  return (
    <div className="p-8">
      <Header />
      <h1 className="text-4xl font-bold mb-8">현재 예약 현황을 띄워주는 관리자 페이지입니다.</h1>
      <div className="space-y-8">
        {seats.length > 0 ? (
          seats.map((seat, index) => (
            <div key={index} className="p-8 border rounded-xl shadow-lg">
              <h2 className="text-3xl font-semibold mb-4">좌석 {index + 1}</h2>
              <p className="text-2xl">상태: {seat.isRented ? '대여 중' : '대여 가능'}</p>
              <p className="text-2xl">잔여 시간: {seat.isRented ? '대여 중' : '없음'}</p>
              <p className="text-2xl">예약한 학생 학번: {seat.studentId ? seat.studentId : '없음'}</p>
              {seat.isRented && (
                <button
                  onClick={() => handleForceCheckout(index)}
                  className="mt-4 px-8 py-4 bg-red-500 text-white text-2xl rounded-lg"
                >
                  강제 퇴실 처리
                </button>
              )}
            </div>
          ))
        ) : (
          <p className="text-2xl">현재 예약된 좌석이 없습니다.</p>
        )}
      </div>
    </div>
  );
};

export default Test;
