import { useRecoilState, useRecoilTransactionObserver_UNSTABLE } from 'recoil';
import { seatState } from '../recoil/recoilState';
import { saveStateToLocalStorage } from '../utils/localStorage';
import { useEffect } from 'react';

const LocalStorageObserver = () => {
  const [seats, setSeats] = useRecoilState(seatState);

  // 좌석 상태를 확인하고 업데이트하는 함수
  const updateSeatStatus = () => {
    const now = new Date();
    const updatedSeats = seats.map((seat) => {
      // 좌석이 대여 중이고 종료 시간이 있을 때
      if (seat.isRented && seat.endTime) {
        const endTime = new Date(seat.endTime);
        if (now >= endTime) {
          // 이용 시간이 종료되면, 좌석 상태를 초기화
          return {
            ...seat,
            isRented: false,
            studentId: null,
            startTime: null,
            endTime: null,
            remainingTime: '00:00:00',
          };
        }
      }
      
      // 좌석이 대여 가능 상태인데 학번 정보가 남아있는 경우, 학번을 초기화
      if (!seat.isRented && seat.studentId) {
        return {
          ...seat,
          studentId: null,
        };
      }

      return seat;
    });

    setSeats(updatedSeats);
    saveStateToLocalStorage('seatState', updatedSeats); // 업데이트된 좌석 상태를 localStorage에 저장
  };

  useEffect(() => {
    // 좌석 상태를 10초마다 확인하는 interval 설정
    const interval = setInterval(() => {
      updateSeatStatus();
    }, 10000); // 10초 간격

    // 컴포넌트가 언마운트될 때 interval을 정리
    return () => clearInterval(interval);
  }, [seats, setSeats]);

  // Recoil 상태가 변경될 때 localStorage에 저장하는 초기 Observer
  useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
    const loadable = snapshot.getLoadable(seatState);
    if (loadable.state === 'hasValue') {
      saveStateToLocalStorage('seatState', loadable.contents); // 상태를 localStorage에 저장
    }
  });

  return null;
};

export default LocalStorageObserver;
