import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import InputStudentId from '../Components/InputStudentId';
import { seatState, returnSeatByStudentIdSelector } from '../recoil/recoilState'; // Recoil 상태 import

const LstudentId = () => {
    const navigate = useNavigate();
    const [seats] = useRecoilState(seatState); // 좌석 상태 가져오기
    const returnSeatByStudentId = useSetRecoilState(returnSeatByStudentIdSelector); // 퇴실 처리 함수
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleConfirm = (studentId) => {
        if (isSubmitting) return; // 중복 제출 방지
        setIsSubmitting(true);

        // 해당 학번으로 대여된 좌석 찾기
        const seatIndex = seats.findIndex(seat => seat.studentId === studentId);

        if (seatIndex === -1) {
            // 해당 학번으로 대여된 좌석이 없으면 알림 표시
            alert('해당 학번으로 예약된 좌석이 없습니다.');
            setIsSubmitting(false); // 다시 제출 가능하게 상태 초기화
        } else {
            // 퇴실 처리
            returnSeatByStudentId(studentId); // Recoil 상태에서 퇴실 처리
            navigate(`/leave/${seatIndex + 1}/complete`); // 퇴실 완료 페이지로 이동
        }
    };

    return (
        <div>
            <InputStudentId onConfirm={handleConfirm} />
        </div>
    );
};

export default LstudentId;
