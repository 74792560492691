import { createGlobalStyle } from 'styled-components';

// Pretendard 폰트를 CDN을 통해 로드
const GlobalStyle = createGlobalStyle`
  @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css');

  html, body {
    font-family: 'Pretendard', sans-serif;
    letter-spacing: -0.75px;
    line-height: 140%;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto; // 스크롤은 가능하되 스크롤바는 숨김
    scrollbar-width: none; // Firefox에서 스크롤바 숨김
    -ms-overflow-style: none; // IE와 Edge에서 스크롤바 숨김

    &::-webkit-scrollbar {
      display: none; // WebKit (Chrome, Opera, Safari 등)에서 스크롤바 숨김
    }
  }

  #root {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default GlobalStyle;
