import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// 이미지 + 텍스트 박스 컴포넌트
const ImageTextBox = ({ imgSrc, altText, text }) => {
  return (
    <div className="flex w-[271px] justify-center items-center gap-[10px]">
      <img src={imgSrc} alt={altText} className="w-10 h-10 object-contain" />
      <span className="font-pretendard text-gray03 text-[27px] tracking-[-1.71px]">{text}</span>
    </div>
  );
};

// 하단 바 컴포넌트
const Footer = () => {
  const [clickCount, setClickCount] = useState(0);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const correctPassword = '1897'; // 올바른 비밀번호

  // 클릭 이벤트 핸들러
  const handleFooterClick = () => {
    if (clickCount + 1 >= 5) {
      setShowPasswordInput(true);
    } else {
      setClickCount(clickCount + 1);
    }
  };

  // 키패드 버튼 클릭 핸들러
  const handleKeypadClick = (num) => {
    if (password.length < 4) {
      setPassword(password + num);
    }
  };

  // 비밀번호 검증 및 라우팅
  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      navigate('/test');
    } else {
      alert('비밀번호가 틀렸습니다.');
      setPassword('');
    }
  };

  // 비밀번호 입력 취소
  const handleCancel = () => {
    setShowPasswordInput(false);
    setPassword('');
  };

  // 입력 창 닫기
  const handleClose = () => {
    setShowPasswordInput(false);
    setPassword('');
    setClickCount(0); // 클릭 카운트 초기화
  };

  return (
    <div
      className="fixed bottom-0 left-0 right-0 flex flex-col justify-center items-center gap-[10px] w-full h-[135px] bg-gray06 flex-shrink-0"
      onClick={handleFooterClick}
    >
      {/* 스타일 적용된 컨테이너 */}
      <div className="flex justify-center items-center gap-[49px]">
        {/* 이미지 + 텍스트 박스들 */}
        <ImageTextBox 
          imgSrc="/img/USSUM.svg"
          altText="Icon 1"
          text="총학생회 US:SUM"
        />
        <ImageTextBox 
          imgSrc="/img/Soongsil.svg"
          altText="Icon 2"
          text="IT지원위원회"
        />
        <ImageTextBox 
          imgSrc="/img/Soongsil.svg"
          altText="Icon 3"
          text="숭실대중앙도서관"
        />
      </div>

      {/* 비밀번호 키패드 입력 창 */}
      {showPasswordInput && (
        <div className="absolute bottom-[150px] bg-white p-4 rounded-lg shadow-lg flex flex-col items-center">
          <div className="mb-2 text-lg font-bold">비밀번호 입력</div>
          <div className="mb-4 text-xl tracking-widest">
            {'*'.repeat(password.length)}
          </div>
          <div className="grid grid-cols-3 gap-2 mb-4">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((num) => (
              <button
                key={num}
                className="w-12 h-12 bg-gray-200 text-lg font-bold rounded-md"
                onClick={() => handleKeypadClick(num)}
              >
                {num}
              </button>
            ))}
          </div>
          <div className="flex gap-2">
            <button
              className="p-2 bg-red-500 text-white rounded-md"
              onClick={handleCancel}
            >
              취소
            </button>
            <button
              className="p-2 bg-blue-500 text-white rounded-md"
              onClick={handlePasswordSubmit}
            >
              확인
            </button>
          </div>
          <button
            className="mt-4 p-1 bg-gray-500 text-white text-sm rounded-md"
            onClick={handleClose}
          >
            창닫기
          </button>
        </div>
      )}
    </div>
  );
};

export default Footer;
