import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import InputStudentId from '../Components/InputStudentId';
import { seatState } from '../recoil/recoilState'; // seatState import

const RstudentId = () => {
    const { id } = useParams(); // URL에서 좌석 ID 추출
    const navigate = useNavigate();
    const [seats, setSeats] = useRecoilState(seatState); // Recoil 상태 관리
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        return () => setIsSubmitting(false); // 컴포넌트 언마운트 시 상태 초기화
    }, []);

    const handleConfirm = (studentId) => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        const seatIndex = parseInt(id, 10) - 1; // 좌석 인덱스 계산 (좌석 번호는 1부터 시작하므로 -1)

        // 이미 해당 학번이 다른 좌석에 등록되어 있는지 확인
        const isStudentAlreadySeated = seats.some(seat => seat.studentId === studentId);

        if (isStudentAlreadySeated) {
            alert('이미 다른 좌석을 이용중입니다!');
            setIsSubmitting(false);
        } else {
            // 좌석 예약 처리
            const now = new Date();
            const endTime = new Date(now.getTime() + 60 * 60 * 1000); // 1시간 뒤

            const updatedSeats = seats.map((seat, index) =>
                index === seatIndex
                    ? {
                        ...seat,
                        isRented: true,
                        startTime: now.toISOString(), // 대여 시작 시간
                        endTime: endTime.toISOString(), // 대여 종료 시간 (1시간 뒤)
                        studentId: studentId, // 학번 등록
                    }
                    : seat
            );

            setSeats(updatedSeats); // 좌석 상태 업데이트
            navigate(`/reservation/${id}/complete`); // 예약 완료 후 ID에 맞춰 /complete 페이지로 이동
        }
    };

    return (
        <div>
            <InputStudentId onConfirm={handleConfirm} />
        </div>
    );
};

export default RstudentId;
