import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';

import Home from './Pages/Home';
import Rcomplete from './Pages/rcomplete';
import Lcomplete from './Pages/lcomplete';
import RstudentId from './Pages/rstudentId';
import LstudentId from './Pages/lstudentId';
import Reservation from './Pages/reservation';
import Test from './Pages/Test';

import GlobalStyle from './Components/GlobalStyle';
import LocalStorageObserver from './utils/LocalStorageObserver'; // LocalStorageObserver 임포트

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Router>
        <LocalStorageObserver />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/reservation/:id/studentId" element={<RstudentId />} />
          <Route path="/reservation/:id/complete" element={<Rcomplete />} />
          <Route path="/leave/studentId" element={<LstudentId />} />
          <Route path="/leave/:id/complete" element={<Lcomplete />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
