import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import Button from '../Components/Button';
import Footer from '../Components/Footer';
import TextContainer from '../Components/TextContainer';
import { availableSeatsSelector } from '../recoil/recoilState'; // Recoil selector import

const Home = () => {
    // Recoil selector를 사용하여 현재 대여 가능한 좌석 수 가져오기
    const availableSeats = useRecoilValue(availableSeatsSelector);

    return (
        <>
            <div className="flex flex-col items-center justify-center min-h-screen gap-[100px] mb-[230px]">
                {/* 로고 및 텍스트 섹션 */}
                <TopText />

                {/* 버튼 섹션 */}
                <div className="flex gap-[29px]">
                    <Button 
                        topText="Reservation"
                        middleText="예약하기"
                        bottomText={`현재 잔여 좌석 ${availableSeats}개`}
                        imgSrc="/img/chair.svg"
                        altText="예약 이미지"
                        route="/reservation"
                    />
                    <Button 
                        topText="Exit"
                        middleText="퇴실하기"
                        bottomText=""
                        imgSrc="/img/Leave.svg"
                        altText="퇴실 이미지"
                        route="/leave/studentId"
                    />
                </div>
            </div>
            <Footer />
        </>
    );
};

const TopText = () => (
    <div className="flex flex-col items-center">
        <img src="/img/Soongsil.svg" alt="Logo" className="w-[240px] h-[97px] mb-10" />
        <TextContainer
            topText="리클라이너 예약시스템"
            bottomText="Recliner Reservation System"
        />
    </div>
);

export default Home;
