import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextContainer from '../Components/TextContainer';

const Lcomplete = () => {
    const { id } = useParams(); // URL에서 좌석 ID 추출
    const navigate = useNavigate();

    // 좌석 ID가 유효하지 않으면 홈으로 리디렉션
    useEffect(() => {
        if (!id || isNaN(id) || id <= 0) {
            navigate('/'); // 유효하지 않은 경우 홈으로 리디렉션
        }
    }, [id, navigate]);

    const handleHomeClick = () => {
        navigate('/'); // 홈으로 이동
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <>
                <img src="/img/Sua.png" alt="complete" className="mb-10" />
                <TextContainer
                    topText={`${id}번 좌석 퇴실 완료되었습니다`}
                    bottomText={`Seat ${id} has been checked out`}
                />
                {/* 홈으로 이동하는 버튼 */}
                <button
                    onClick={handleHomeClick}
                    className="flex justify-center items-center w-[750px] h-[130px] p-10 gap-10 rounded-[130px] bg-[#006E93] text-white text-[48px] font-normal">
                    홈으로 
                </button>
            </>
        </div>
    );
};

export default Lcomplete;
