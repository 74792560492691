import { atom, selector } from 'recoil';
import { loadStateFromLocalStorage } from '../utils/localStorage';

const LOCAL_STORAGE_KEY = 'seatState';

// 좌석 정보를 관리하는 atom
export const seatState = atom({
  key: 'seatState',
  default: loadStateFromLocalStorage(LOCAL_STORAGE_KEY) || Array.from({ length: 6 }, (_, i) => ({
    seatNumber: i + 1,
    isRented: false,
    startTime: null,
    endTime: null,
    studentId: null,
  })),
});

// 좌석 예약 처리 selector
export const reserveSeatSelector = selector({
  key: 'reserveSeatSelector',
  get: () => null, // get은 사용하지 않음
  set: ({ get, set }, { seatIndex, studentId }) => {
    const seats = get(seatState); // 현재 좌석 상태를 가져옴

    // 좌석 인덱스가 유효한지 확인 (0 ~ 좌석 수 - 1)
    if (seatIndex < 0 || seatIndex >= seats.length) {
      console.error('유효하지 않은 좌석 번호입니다.');
      return;
    }

    // 해당 좌석이 이미 대여 중인지 확인
    if (seats[seatIndex].isRented) {
      console.error('해당 좌석은 이미 대여 중입니다.');
      return;
    }

    // 1시간 대여 시간 설정 (현재 시간 + 1시간)
    const now = new Date();
    const endTime = new Date(now.getTime() + 60 * 60 * 1000); // 1시간 후

    // 해당 좌석을 대여 중으로 설정
    const updatedSeats = seats.map((seat, index) =>
      index === seatIndex
        ? {
            ...seat,
            isRented: true,
            startTime: now.toISOString(), // 대여 시작 시간
            endTime: endTime.toISOString(), // 대여 종료 시간
            studentId: studentId, // 대여자의 학번
          }
        : seat
    );

    set(seatState, updatedSeats); // 좌석 상태 업데이트
  },
});

// 좌석 상태 업데이트: 좌석이 사용 종료되는 경우만 상태를 업데이트
export const updateSeatStatusSelector = selector({
  key: 'updateSeatStatusSelector',
  get: () => null, // get은 사용하지 않음
  set: ({ get, set }) => {
    const seats = get(seatState); // 현재 좌석 상태를 가져옴
    const now = new Date();

    // 종료 시간이 도달한 좌석의 상태만 업데이트
    const updatedSeats = seats.map((seat) => {
      if (seat.isRented && new Date(seat.endTime) <= now) {
        return {
          ...seat,
          isRented: false,         // 대여 상태를 '대여 가능'으로 변경
          studentId: null,         // 학번 정보 초기화
          startTime: null,         // 시작 시간 초기화
          endTime: null,           // 종료 시간 초기화
        };
      }
      return seat;
    });

    set(seatState, updatedSeats); // 좌석 상태 업데이트
  },
});

// 대여 가능한 좌석 수를 계산하는 selector
export const availableSeatsSelector = selector({
  key: 'availableSeatsSelector',
  get: ({ get }) => {
    const seats = get(seatState); // 현재 좌석 상태를 가져옴
    // 대여 가능 상태인 좌석 필터링
    const availableSeats = seats.filter(seat => !seat.isRented);
    return availableSeats.length; // 대여 가능 상태인 좌석 수 반환
  },
});

// 학번을 입력 받아 퇴실 처리하는 selector
export const returnSeatByStudentIdSelector = selector({
  key: 'returnSeatByStudentIdSelector',
  get: () => null, // get은 사용하지 않음
  set: ({ get, set }, studentId) => {
    const seats = get(seatState); // 현재 좌석 상태를 가져옴
    const seatIndex = seats.findIndex(seat => seat.studentId === studentId); // 학번으로 좌석 찾기

    if (seatIndex === -1) {
      console.error('해당 학번으로 대여된 좌석이 없습니다.');
      return;
    }

    // 해당 좌석의 대여 상태를 초기화 (퇴실 처리)
    const updatedSeats = seats.map((seat, index) =>
      index === seatIndex
        ? {
            ...seat,
            isRented: false,
            startTime: null,
            endTime: null,
            studentId: null,
          }
        : seat
    );

    set(seatState, updatedSeats); // 좌석 상태 업데이트
  },
});

// 좌석 인덱스를 입력 받아 퇴실 처리하는 selector
export const returnSeatByIndexSelector = selector({
  key: 'returnSeatByIndexSelector',
  get: () => null, // get은 사용하지 않음
  set: ({ get, set }, seatIndex) => {
    const seats = get(seatState); // 현재 좌석 상태 가져옴

    // 좌석 인덱스가 유효한지 확인 (0 ~ 좌석 수 - 1)
    if (seatIndex < 0 || seatIndex >= seats.length) {
      console.error('유효하지 않은 좌석 번호입니다.');
      return;
    }

    // 해당 좌석 퇴실 처리 (대여 가능 상태로 변경)
    const updatedSeats = seats.map((seat, index) =>
      index === seatIndex
        ? {
            ...seat,
            isRented: false,
            startTime: null,
            endTime: null,
            studentId: null,
          }
        : seat
    );

    set(seatState, updatedSeats); // 상태 업데이트
  },
});
