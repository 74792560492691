import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextContainer from '../Components/TextContainer';

const Rcomplete = () => {
    const { id } = useParams(); // URL에서 좌석 ID 추출
    const navigate = useNavigate();

    // 홈으로 이동하는 버튼 클릭 핸들러
    const handleHomeClick = () => {
        navigate('/'); // 홈으로 이동
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            {/* 예약 완료 메시지 */}
            <TextContainer
                topText="1시간 예약이 완료되었습니다"
                bottomText="Your reservation for an hour has been completed"
            />

            {/* 좌석 ID 표시 */}
            <div className="text-center text-[120px] font-bold mt-[150px] mb-[200px]">
                {id}번
            </div>

            {/* 홈으로 이동하는 버튼 */}
            <button
                onClick={handleHomeClick}
                className="flex justify-center items-center w-[750px] h-[130px] p-10 gap-10 rounded-[130px] bg-[#006E93] text-white text-[48px] font-normal">
                홈으로 
            </button>
        </div>
    );
};

export default Rcomplete;
