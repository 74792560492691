import React from 'react';

const TextContainer = ({ topText, bottomText }) => {
  return (
    <div className="flex flex-col items-center w-full max-w-[821px] mx-auto mt-[90px]">
      {/* 상단 텍스트 */}
      <h1 className="text-center text-gray01 font-pretendard text-[57px] font-semibold leading-normal tracking-[-1.71px] whitespace-nowrap">
        {topText}
      </h1>

      {/* 하단 텍스트 */}
      <p className="text-center text-gray01 font-pretendard text-[43px] font-normal leading-normal tracking-[-1.29px] whitespace-nowrap mb-[20px]">
        {bottomText}
      </p>
    </div>
  );
};

export default TextContainer;
